@font-face {
    font-family: HelloBorden;
    src: url('fonts/Borden.otf') format("opentype");
}

@font-face {
    font-family: 'Cinzel Regular';
    src: url('fonts/Cinzel-Regular.ttf') format("truetype");
}

.ui.cards>.card>.image {
    background: none !important;
}

.main-header {
    font-family: HelloBorden;
}

.wrapped{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.link-on-dark {
    color:burlywood;
}
.link-on-dark:hover {
    color:chocolate;
}